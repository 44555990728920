<template>
  <div>
    <!--    <div class="title flexB">
          <h1>{{ $t("616") }}</h1>
        </div>-->
    <div class="contents transcoder">
      <el-tabs type="border-card" id="tab" class="small">
        <el-tab-pane v-for="(data, i) in serverList" :key="i">
          <span slot="label">{{ data.name }}</span>
          <Server :serverId="data.serverId" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Server from "@/components/vod/Server";
import moment from "moment";
import { fetchServerList } from "@/api/server";
export default {
  components: { Server },

  name: "TranscoderServer",

  data() {
    return {
      moment: moment,
      visible: 1,
      serverList: [],
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 6, sub: 2 });
  },
  mounted() {
    this.getServerList();
  },
  methods: {
    handleTab(id) {
      this.visible = id;
    },
    getServerList() {
      let type = "Transcoder";
      fetchServerList(type).then((res) => {
        this.serverList = res.data.data;
      });
    },
  },
};
</script>
